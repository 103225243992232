import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { City, Country, Region } from '@shared/interfaces';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  constructor(private http: HttpClient) {}

  /**
   * Retrieves all the regions.
   * @returns An observable of the regions list response.
   */
  getAllRegions(): Observable<Region[]> {
    return this.http.get<Region[]>(`assetmanagement/regions/?ordering=name`);
  }

  /**
   * Retrieves all the countries of specific region.
   * @param regionId - The ID of the region.
   * @returns An observable of the countries by region response.
   */
  getAllCountriesByRegion(regionId: string): Observable<Country[]> {
    return this.http.get<Country[]>(`assetmanagement/regions/${regionId}/countries/?ordering=name`);
  }

  /**
   * Retrieves all the cities of specific country.
   * @param countryId - The ID of the country.
   * @returns An observable of the cities by country response.
   */
  getAllCitiesByCountry(countryId: string): Observable<City[]> {
    return this.http.get<City[]>(`assetmanagement/countries/${countryId}/cities/?ordering=name`);
  }
}
